import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

const SapCertification = () => {
  const { certificationImage } = useStaticQuery( 
    graphql`
      query {
        certificationImage: file(relativePath: { eq: "c_hanadev_15_LS.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 322, maxHeight: 321) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return <S.ImgWrapper fluid={certificationImage.childImageSharp.fluid} />
}

export default SapCertification