const sapSkills = [
  {
    module: 'ABAP',
    skill1: 'Write/ALV Reports',
    skill2: 'SmartForms/SapScript/Adobe Forms',
    skill3: 'File upload and writing',
  },
  {
    module: 'ABAP',
    skill1: 'Call Transaction',
    skill2: 'Batch input',
    skill3: 'BAPI',
  },
  {
    module: 'ABAP',
    skill1: 'Badi',
    skill2: 'RFC',
    skill3: 'Exit',
  },
  {
    module: 'ABAP',
    skill1: 'Enhancement',
    skill2: 'BTE',
    skill3: 'ABAP Objects',
  },
  {
    module: 'ABAP',
    skill1: 'RTTS',
    skill2: 'PI-PO',
    skill3: 'IDOC',
  },
  {
    module: 'ABAP',
    skill1: 'Proxy',
    skill2: 'Mail',
    skill3: 'FILE',
  },
  {
    module: 'ABAP',
    skill1: 'SOAP',
    skill2: 'JDBC',
    skill3: 'OSS Notes',
  },
  {
    module: 'ABAP',
    skill1: 'Data dictionary',
    skill2: 'Trace and performance tuning',
    skill3: 'HINTS',
  },
  {
    module: 'ABAP',
    skill1: 'CodePushdown for HANA',
    skill2: 'CDS Views and AMDP',
    skill3: 'OData',
  },
  {
    module: 'ABAP',
    skill1: 'Web IDE',
    skill2: 'CPI',
    skill3: 'WorkFlow ABAP',
  },
  {
    module: 'ABAP',
    skill1: 'UI5',
    skill2: 'Fiori',
    skill3: 'SAP HANA 2.0',
  },
  {
    module: 'ABAP',
    skill1: 'SQLScript',
    skill2: 'SAP Gateway',
    skill3: 'Node.js',
  },
  {
    module: 'ABAP',
    skill1: 'SOAP/REST/gRPC',
    skill2: 'SAP Activate',
    skill3: '',
  }
]

export default sapSkills