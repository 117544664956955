import styled from 'styled-components'
import media from "styled-media-query"
import Img from 'gatsby-image'

export const  ImgWrapper = styled(Img)`
  border-radius: .5rem;
  display: block;
  filter: drop-shadow(0 0.2rem 0.55rem rgba(2, 2, 2, 0.7));
  margin: auto;
  //max-width: 50%; //322
  //max-height: 70%;
  width: 58%;
  height: auto;

  picture > img{
    //height: auto !important;
    display: block;
    //max-width: 50%;//322px;
    //max-height: 50%;//321px;
    margin: auto;
    border-radius: .5rem;
    //filter: drop-shadow(1rem 1rem 15rem rgba(2, 2, 2, .5));
  }
  > img {
    //height: auto !important;
  }

  ${media.lessThan("medium")`
    margin: auto;
    width: 45%;
  `}
`