import React from 'react'
//import Particles from 'react-particles-js';

import Layout from '../components/layout/'
import SEO from '../components/seo'
import SocialLinks from '../components/socialLinks'
//import ParticlesContainer from "../components/particlescontainer";
import SapCertification from '../components/SapCertification';
import CertificationAI from '../components/CertificationAI';
//import ViewCounter from "../components/ViewCounter"

import {  MainContent, 
          SkillsWrapper, 
          SkillBox,  
          SkillItens,
          SkillJS,
          SkillNodejs,
          SkillPython,
          SkillHtml,
          SkillCSS,
          SkillJReact,
          SkillFlask,
          SkillData,
          SkillGraph,
          SkillGit,
          SkillAws,
          SkillDevices,
          SkillDocker,
          SkillCloud,
          SapTable,
          ColumnDivisor,
          CertificationsWrapper } from '../styles/base'
//import getParticleColor from "../utils/getParticleColor"
import sapSkills from '../pagecontents/sapskills'


const AboutPage = () => (
  <Layout>
    <SEO
      title="Sobre mim"
      description="Saiba um pouco mais sobre o desenvolvedor por trás deste blog."
    />
    <MainContent>
      <h1 class="aboutGreetings">Olá, eu sou o Seixas</h1>
      <p class="idented">
        Nasci no RJ e sou apaixonado por compartilhamento de conhecimento,
        acredito ser uma das melhores formas de se melhorar o nosso redor, acho que foi um bom motivo 
        para criar esse blog a fim de documentar e compartilhar tudo o que eu aprendo.
      </p>

      <p class="idented">
        Cursei <abbr title="Ciência da Computação">CC</abbr> na <abbr title="Universidade Candido Mender">UCAM</abbr> e atualmente curso Pós em <abbr title="Inteligência Artificial">AI/ML</abbr> na PUC Minas. 
        Atualmente sou <strong class="strong72">Desenvolvedor</strong> <strong class="strong72">Consultor SAP ABAP</strong> na {' '}
        <a href="https://www.tcs.com" target="_blank" rel="noopener noreferrer">
          TCS
        </a>.
      </p>

      <p class="idented">
        No tempo livre curto jogar qualquer coisa seja no PC ou consoles, assistir um filme ou série,
        eventualmente ler um livro pra consumir algum conteúdo interessante fora do PC. 
        Pratico airsoft e curto uma boa música(quanto mais experimental, melhor).<br />
      </p>

      <h2>Jornada ao SAP</h2>
      <p class="idented">Por mais de 5 anos em contato com o <em>SAP</em>, sendo eles primeiramente sobre suporte aos diversos
        módulos possibilitando um conhecimento mais amplo de toda landscape desse
        gigante <abbr title="Enterprise Resource Planning">ERP</abbr>, tive a oportunidade de entrar em  contato com <abbr title="Advanced Business Application Programming">ABAP</abbr> por
        ser dentro da minha área de especialidade
        (<strong class="strong72">Desenvolvimento de Software</strong>), possibilitou-me enxergar na prática como a <em>SAP</em> cria ferramentas
        interessantes que mesclam a área funcional e técnica sem que seja intimidador.
      </p>
      <SapTable>
        <tr>
          <th></th>
          <th>SAP ABAP Tech</th>
          <th></th>
        </tr>
        {sapSkills.map((tech, i) => {
          return (
            <tr key={i}>
              <td>{tech.skill1}</td>
              <td>{tech.skill2}</td>
              <td>{tech.skill3}</td>
            </tr>
          );
        })}
      </SapTable>
      <h3>Certificados</h3>
    </MainContent>

      <CertificationsWrapper>
        <ColumnDivisor>
          <a href="https://www.youracclaim.com/badges/49ffc306-fb73-4741-a4d1-c88cf626fdc8"
            target="_blank" 
            rel="noopener noreferrer" 
            title="SAP Certified Development Associate - SAP HANA 2.0 (SPS03) C_HANADEV_15"
            >
            <SapCertification />
          </a>
        </ColumnDivisor>
        <ColumnDivisor>
          <a href="https://www.coursera.org/account/accomplishments/certificate/G6GUK73YMDEM" 
            target="_blank" 
            rel="noopener noreferrer"
            title="Coursera: deeplearning.ai - Neural Networks & Deep Learning"
          >
            <CertificationAI />
          </a>
        </ColumnDivisor>
      </CertificationsWrapper>
      <MainContent><h2>Habilidades</h2></MainContent>
      <SkillsWrapper>
        <SkillBox>
          <SkillItens>
            <SkillJS />
            <SkillNodejs />
            <SkillPython />
          </SkillItens>
          <h3>BackEnd</h3>
          <br></br>
          <li>JavaScript(NodeJS)</li>
          <li>Python</li>
          <li>Java</li>
          <li>SOAP/REST/gRPC</li>
          <li>JSON/XML</li>
          <li>MongoDB/SQL/Redis</li>
        </SkillBox>
        <SkillBox>
          <SkillItens>
            <SkillJReact />
            <SkillHtml />
            <SkillCSS />
          </SkillItens>
          <h3>FrontEnd</h3>
          <br></br>
          <li>JavaScript(ReactJS, HTML5, CSS)</li>
          <li>GraphQL</li>
          <li>Markdown</li>
        </SkillBox>
        <SkillBox>
          <SkillItens>
            <SkillFlask />
            <SkillGraph />
            <SkillPython />
            <SkillData />
          </SkillItens>
          <h3>DataScience</h3>
          <br></br>
          <li>Python</li>
          <li>Flask/TensorFlow Serving</li>
          <li>Jupyter Notebook</li>
        </SkillBox>
        <SkillBox>
          <SkillItens>
            <SkillGit />
            <SkillAws />
            <SkillDevices />
            <SkillDocker />
            <SkillCloud />
          </SkillItens>
          <h3>DevOps & General</h3>
          <br></br>
          <li>Git</li>
          <li>Docker</li>
          <li>SCRUM</li>
        </SkillBox>
      </SkillsWrapper>

    <MainContent>
      <h2>Contato</h2>

      <p>
        Você pode entrar em contato comigo através de qualquer uma das minhas
        redes sociais.
      </p>

      <SocialLinks hideStyle />
    </MainContent>
  </Layout>
)

export default AboutPage