import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

const CertificationAI = () => {
  const { certificationAIImage } = useStaticQuery( 
    graphql`
      query {
        certificationAIImage: file(relativePath: { eq: "deeplearningAI_LS.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 726, maxHeight: 555) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return <S.ImgAIWrapper fluid={certificationAIImage.childImageSharp.fluid} />
} //deeplearningAI_LS.jpg

export default CertificationAI