const handle = 'Seixas'

const links = [
  {
    label: 'Github',
    url: `https://github.com/${handle}`,
  },
  /*{
    label: 'Twitter',
    url: 'https://twitter.com/404notreally',
  },*/
  {
    label: 'Linkedin',
    url: 'https://linkedin.com/in/seixasdev',
  },
  {
    label: 'Instagram',
    url: 'https://instagram.com/seixasdev'
  }
]

export default links