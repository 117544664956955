import styled from 'styled-components'
import media from 'styled-media-query'
//import Img from 'gatsby-image'

import { Javascript, ReactLogo, Css3, Html5, Nodejs, Git } from '@styled-icons/boxicons-logos'
import { Cloud } from '@styled-icons/boxicons-regular'
import { Flask, Data } from '@styled-icons/boxicons-solid'
import { Docker, Python, Aws } from '@styled-icons/fa-brands'
import { Memory } from '@styled-icons/material'
import { Graph } from '@styled-icons/octicons'
import { Devices } from '@styled-icons/material-rounded'

import * as V from '../styles/variables'

const Icon = `
  display: block;
  height: 2rem;
  width: 2rem;
  margin: .5rem;
`

const SkillIconHover = `
  transition: color ${V.Transition.default}, transform ${V.Transition.default}; /* Animation */
  &:focus,
  &:hover {
    color: var(--highlight);
    transform: scale(1.5);
  }
`

const SkillCardHover = `
  transition: box-shadow ${V.Transition.default}, transform ${V.Transition.default} ease; /* Animation */
  &:focus,
  &:hover {
    //transform: scale(1.1);
    transform: translateY(-9px);
    box-shadow: 2px 15px 4px var(--boxShadow);
  }
`

export const SkillItens = styled.nav`
  color: var(--shellText);
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center; /*or space-between */
  height: ${V.Height.footer};
  padding-left: ${V.Space.sm};
  padding-right: ${V.Space.sm};
`

export const SkillJS = styled(Javascript)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillNodejs = styled(Nodejs)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillPython = styled(Python)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillJReact = styled(ReactLogo)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillCSS = styled(Css3)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillHtml = styled(Html5)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillFlask = styled(Flask)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillData = styled(Data)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillMemory = styled(Memory)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillGraph = styled(Graph)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillGit = styled(Git)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillAws = styled(Aws)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillDevices = styled(Devices)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillDocker = styled(Docker)`
  ${Icon}
  ${SkillIconHover}
`
export const SkillCloud = styled(Cloud)`
  ${Icon}
  ${SkillIconHover}
`


export const PostHeader = styled.header`
  color: var(--postColor);
  margin: auto;
  max-width: 70rem;
  padding: 5rem 5rem 0;
  ${media.lessThan('medium')`
    padding: 3rem 0 0;
    max-width: 100%;
  `}
`

export const PostTitle = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  padding: 0 1.4rem;
  margin: 1rem auto;
  ${media.lessThan('medium')`
    font-size: 2.8rem;
    line-height: 1.1;
    padding: 0 1rem;
  `}
`

export const PostDescription = styled.h2`
  font-size: 2rem;
  font-weight: 200;
  padding: 0 1.4rem;
  ${media.lessThan('medium')`
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 0 1rem;
  `}
`

export const PostDate = styled.p`
  font-size: 1.1rem;
  font-weight: 100;
  padding: 0 1.4rem;
  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`

export const MainContent = styled.section`
  margin: auto;
  max-width: 70rem;
  padding: 2rem 5rem;
  ${media.lessThan('medium')`
    padding: 2rem 0;
    max-width: 100%;
  `}
  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  .tags,
  iframe,
  .button-post {
    color: var(--shellText);
    font-size: 1.235rem;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: 0.069rem;
    padding: 0 1.4rem;
    ${media.lessThan('medium')`
      padding: 0 1rem;
      word-break: break-word;
    `}
  }
  p {
    display: block;
    text-align: justify;
    margin: 0 auto 1.6rem;

    &.idented {
      text-indent: 20px;
    }
  }
  h1 {
    &.aboutGreetings {
      &:before {
        content: "★";
        margin-right: .5rem;
        font-family: "Source Code Pro", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
        color: var(--greetings);
        font-size: 1.5rem;
        position: relative;
        top: -0.4875rem;

        ${media.lessThan('medium')`
          margin-right: .5rem;
          top: -0.2rem;
        `}
      }
      &:after {
          content: "";
          display: block;
          height: 1px;
          max-width: 17.5rem;
          width: 100%;
          background-color: var(--menuHover);
          position: relative;
          top: -1.6rem;
          margin-left: 31.5rem;
          ${media.lessThan('medium')`
            top: -1.2rem;
            margin-left: 22rem;
          `}
      }
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 2.4rem auto 1rem;
  }
  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
  }
  /*img {
    display: block;
    max-width: 100%;
    margin: 1.875rem auto;
  }*/
  iframe {
    padding: 0 1.6rem 1.6rem;
    width: 100%;
    ${media.lessThan('medium')`
      padding: 0 1rem;
    `}
  }
  blockquote {
    color: var(--postColor);
    border-left: 0.3rem solid var(--highlight);
    padding: 0 1.875rem;
    margin: 3.125rem auto;
  }
  hr {
    border: 1px solid var(--borders);
    margin: 3rem auto;
  }
  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 800;
    letter-spacing: 0.069rem;
    line-height: 1.4;
  }
  h1 {
    font-size: 2.8rem;
    ${media.lessThan('medium')`
      font-size: 1.875rem;
    `}
  }
  h2 {
    font-size: 2.1rem;
    ${media.lessThan('medium')`
      font-size: 1.375rem;
    `}
  }
  h3 {
    font-size: 1.6rem;
    ${media.lessThan('medium')`
      font-size: 1.125rem;
    `}
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  strong {
    font-weight: 700;
  }
  /*img{
    height: auto !important;
    display: block;
    max-width: 322px;
    max-height: 321px;
    margin: auto;
    border-radius: 2rem;
    //filter: drop-shadow(1rem 1rem 15rem rgba(2, 2, 2, .5));
  }*/
  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 1 !important;
    border-radius: .5rem;
  }
  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;
    border-radius: .5rem;
    &.lazyload {
      opacity: 0;
      border-radius: .5rem;
    }
    &.lazyloaded {
      opacity: 1;
      z-index: 3;
      border-radius: .5rem;
    }
  }
  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;
    ${media.lessThan('medium')`
      padding: 0;
    `}
  }
  .instagram-media {
    margin: 1rem auto !important;
  }
  a {
    //border-bottom: 1px dashed var(--highlight);
    border-bottom: 1px dotted var(--menuHover);
    color: var(--menuHover);
    text-decoration: none;
    transition: opacity 0.5s;
    svg {
      color: var(--postColor);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  abbr {
    border-bottom: 1px dotted;
  }
  em {
    &.emNormal {
      font-style: normal;
    }
  }
  strong {
    &.strong72 {
      font-weight: 100;
    }
  }
  /*figure {
    display: flex;
    align-items: center;
  }*/
  figcaption{
    display: inline-block;
    vertical-align: middle;
  }
`



export const SapTable = styled.table`
  width: 100%;
  border-spacing: 5px 5px;
  border-collapse: separate;
  border-radius: 5px;
  background: var(--primaryColor);
  //border: 1px solid black;
  //box-shadow: 2px 3px 4px var(--boxShadow);
  th,
  td {
    //border: 1px solid black;
    border-collapse: separate;
    border-spacing: 5px 5px;
  }
  th,
  td,
  tr {
    padding: 3px;
    font-size: 1rem;
    margin-top: 5%;
    color: var(--shellText);
  }
  th {
    text-align: center;
  }
  td.td-center {
    text-align: center;
  }
  td.td-right {
    text-align: right;
  }
  li {
    padding: 1.5px;
  }
`
export const CertificationsWrapper = styled.section`
  text-align: left;
  display: flex;
  padding-top: ${V.Space.xxs};
  padding-bottom: ${V.Space.sm};
  margin: auto;
  max-width: 80rem;
  height: auto;
  //background: #f90;
`

export const ColumnDivisor = styled.div`
  flex: 50%;
  padding: 5px;
  //background: #fff;

  ${media.lessThan('medium')`
    padding: 0;
    justify-content: left;
    text-align: left;
  `}
`

export const SkillsWrapper = styled.section`
  text-align: left;
  padding-top: ${V.Space.xxs};
  padding-bottom: ${V.Space.sm};
  margin: auto;
  max-width: 80rem;
  height: auto;
  //background: #f90;
`

export const SkillBox = styled.div`
  ${SkillCardHover}
  box-shadow: 2px 3px 4px var(--boxShadow);
  display: inline-block;
  vertical-align: bottom;
  color: var(--primaryColor);
  font-size: 1.1em;
  width: 18rem;
  height: 18rem;
  background: var(--primaryColor);
  //top: .5rem;
  //left: .5rem;
  margin: 1.8rem 0 0 1.8rem;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 0;
  text-align: left;
  text-justify: inter-word;

  h3 {
    text-align: center;
    font-size: 1.3em;
    color: var(--shellText);
  }

  h5 {
    text-align: center;
    font-size: .9em;
    font-weight: 400;
    margin-top: 3%;
    color: var(--shellText);
  }

  p {
    font-size: .8em;
    margin-top: 5%;
    color: var(--shellText);
  }

  li {
    color: var(--shellText);
  }
`