import React from 'react'
import ReactGA from 'react-ga'

import Icons from './icons'
import links from './content'

import * as S from './styled'

const trackSocialClick = (item) => {
  ReactGA.event({
    category: 'Social',
    action: 'click',
    label: `Social - ${item}`
  })
}


const SocialLinks = () => (
  <S.SocialLinksWrapper>
    <S.SocialLinksList>
      {links.map((link, i) => {
        const Icon = Icons[link.label]

        return (<S.SocialLinksItem key={i}>
          <S.SocialLinksLink 
            href={link.url}
            title={link.label}
            onClick={() => trackSocialClick(link.label)}
            target="_blank"
            rel="noopener noreferrer">
              <S.IconWrapper>
                <Icon />
              </S.IconWrapper>
          </S.SocialLinksLink>
        </S.SocialLinksItem>)
      })}
    </S.SocialLinksList>
  </S.SocialLinksWrapper>
)

export default SocialLinks